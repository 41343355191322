import React, { FC, useEffect, useRef, useState } from 'react'
import SiteInfoStore from '../../stores/SiteInfoStore'
import { breakpoints } from '../../constants/Layout.js'
import { User } from '../../api/Users/Types'
import Button from '../common/buttons/Button'
import httpClient from '../../api/httpClient'

interface Props {
  trackingKey?: string
  disableOnMobile?: boolean
  label?: string
  className?: string
  anchorTag?: boolean
  anchorLink?: string
  email?: string
  analyticId?: string
  onClickCallback?: () => void
}

const StartBuildingButton: FC<Props> = props => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [onboardingUrl, setOnboardingUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    SiteInfoStore.getCurrentUser(currentUser => {
      setCurrentUser(currentUser)
    })
    SiteInfoStore.getOnboardingUrl(onboardingUrl => {
      setOnboardingUrl(onboardingUrl)
    })
  }, [])

  const trackClick = () => {
    if (props.trackingKey) {
      GoogleAnalyticsHelper.VentionGAEvent(props.trackingKey, 'click', 'funnel', currentUser?.id)
    }
  }

  const onButtonClick = async () => {
    trackClick()
    if (props.onClickCallback) {
      props.onClickCallback()
    }

    if (!currentUser) {
      window.location.href = '/signup'
    } else if (currentUser.visitor && currentUser.done_onboarding_tutorial) {
      window.location.href = '/activation'
    } else if (!currentUser.done_onboarding_tutorial) {
      window.location.href = onboardingUrl
    } else {
      // @ts-ignore
      try {
        const response = await httpClient.post('/navbar/designs')
        window.open(`/machine-builder/${response.data.design_id}`, '_blank')
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  }

  const label = props.label || 'Start Building'
  const className = props.className || 'button button-success'
  const disabled = props.disableOnMobile ? window.innerWidth <= breakpoints.navMainLarge : false

  return (
    <>
      {props.anchorTag ? (
        <a href={props.anchorLink} className={className} data-analytic-id={props.analyticId}>
          {label}
        </a>
      ) : (
        <Button
          className={className}
          onClick={onButtonClick}
          disabled={disabled}
          isLoading={isLoading}
          data-analytic-id={props.analyticId}
        >
          {label}
        </Button>
      )}
    </>
  )
}

export default StartBuildingButton
